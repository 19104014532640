import React from "react";

export const HomeFloatingButton: React.FC = () => {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=6285694072347&text=Halo%2C%20Tim%20goKampus.%20Saya%20mau%20mendapatkan%20free%20subscription%20paket%20%22Enterprise%22%20goKampus%20for%20Business.%20Bagaimana%20caranya%3F"
      target="_blank"
      className="floating-button items-center justify-center flex"
    >
      <i className="fa fa-whatsapp "></i>
    </a>
  );
};
