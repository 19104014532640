import DataOptions from "data/locales/id/tabs.json"


export const TabsCustom: React.FC<{
  data?: any;
  selectedItem?: any;
  onSelectItem?: (e: any) => void;
  id?: string; // Attribute that you have created on tabs.json
  useMinWidth?: boolean;
}> = ({ data, selectedItem, onSelectItem, id, useMinWidth }) => {
  const optionContent = id ? DataOptions[id] : data;

  return (
    <>
      {optionContent &&
        optionContent.map((item: any) => {
          const selectedClassName =
            "text-b2 p-8 pb-8 text-center text-purple-50 border-b-4 border-purple-50";
          const defaultClassName =
            "text-b2 p-8 pb-10 text-center font-medium";

          return (
            <div
              key={item.name}
              className={`cursor-pointer ${useMinWidth ? 'min-w-[200px] flex justify-center items-center' : 'w-fit'} ${[item.name, item.id].includes(selectedItem)
                ? selectedClassName
                : defaultClassName
                }`}
              onClick={(_) => onSelectItem(item)}
            >
              {item.name}
            </div>
          );
        })}
    </>
  );
};
