import React, { useEffect, useRef, useState } from "react";
import FooterBusiness from "components/Footer/FooterBusiness";
import PageHeaderBusiness from "components/PageHeader/PageHeaderBusiness";
import { HomeDescription } from "modules/Business/HomeDescription";
import { HomeInstitutions } from "modules/Business/HomeInstitutions";
import { HomeBenefit } from "modules/Business/HomeBenefit";
import { HomeAccess } from "modules/Business/HomeAccess";
import { HomeContacts } from "modules/Business/HomeContacts";
import { useIsDesktop } from "lib/device";
import { HomePackets } from "modules/Business/HomePackets";
import { HomePopUp } from "modules/Business/HomePopUp";
import { iconColor } from "modules/Nav/BottomNav";
import { usePopupStoreFormSales } from "modules/Home/popupStore";
import Modal from "components/Modal";
import { HomeCompanies } from "modules/Business/HomeCompanies";
import PageHeaderPricing from "components/PageHeader/PageHeaderPricing";
import { HomeTestimony } from "modules/Business/HomeTestimony";
import { HomeFloatingButton } from "modules/Home/HomeFloatingButton";
import { VideoPopUp } from "modules/Business/VideoPopUp";
import { HomeInformation } from "modules/Business/HomeInformation";

const HomeBusiness: React.FC = () => {
  const [modeTransparent, setMode] = useState(true);
  const [isShowHeaderPricing, setisShowHeaderPricing] = useState(false);
  const isDesktop = useIsDesktop();
  const scrollOffset = isDesktop ? 90 : 56;
  const { isShow, closePopupFormSales, isShowYoutubeModal, closePopupYoutube } =
    usePopupStoreFormSales((state) => ({
      isShow: state.isShow,
      closePopupFormSales: state.closePopupFormSales,
      isShowYoutubeModal: state.isShowYoutubeModal,
      closePopupYoutube: state.closePopupYoutube,
    }));

  const pricingRef = useRef();

  useEffect(() => {
    function onScroll(e: Event) {
      if (window.scrollY >= scrollOffset) {
        setMode(false);
      } else if (window.scrollY < scrollOffset) {
        setMode(true);
      }

      // Calculate the scroll threshold dynamically based on the reference element's position
      //@ts-ignore
      let referenceElementPosition = pricingRef.current.getBoundingClientRect();
      let scrollThreshold =
        window.innerHeight - (referenceElementPosition.top + 800);

      if (scrollThreshold > 0 && scrollThreshold < 550) {
        // Show the component when the scroll position is greater than or equal to the threshold
        setisShowHeaderPricing(true);
      } else {
        // Hide the component when the scroll position is less than the threshold
        setisShowHeaderPricing(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <link
        rel="stylesheet"
        href="https://unpkg.com/swiper@7/swiper-bundle.min.css"
      />
      <link itemProp="thumbnailUrl" href="/icon-default.png"></link>
      <span
        itemProp="thumbnail"
        itemScope
        itemType="http://schema.org/ImageObject"
      >
        <link itemProp="url" href="/icon-default.png"></link>
      </span>

      <main className="bg-[url('/images/image-bg-desktop.webp')] bg-no-repeat bg-cover sm-max:bg-[url('/images/image-bg-mobile.webp')]">
        <PageHeaderBusiness isTransparent={modeTransparent} />
        {isShowHeaderPricing && <PageHeaderPricing />}
        <HomeDescription />
        <HomeInstitutions />
        <HomeInformation />
        <HomeBenefit />
        <HomeCompanies />
        <HomeAccess />
        <div ref={pricingRef}>
          <HomePackets />
        </div>
        <HomeTestimony />
        <HomeContacts />
        <FooterBusiness />
        <Modal
          key={"modal-youtube"}
          className="bg-[url('/images/image-bg-desktop.webp')] bg-no-repeat bg-cover sm-max:bg-[url('/images/image-bg-mobile.webp')]"
          contentClassName="!bg-transparent"
          childrenClassName="lg:h-[85vh] lg:flex"
          color={iconColor.default}
          isShow={isShowYoutubeModal}
          onClose={closePopupYoutube}
          isFullScreen={true}
          isTopBorderTransparent={true}
          isWidthScreen="90vw"
        >
          <VideoPopUp />
        </Modal>
        <Modal
          key={"modal-popup-sales"}
          className="bg-[url('/images/image-bg-desktop.webp')] bg-no-repeat bg-cover sm-max:bg-[url('/images/image-bg-mobile.webp')]"
          contentClassName="!bg-transparent"
          childrenClassName="lg:h-[85vh] lg:flex"
          color={iconColor.default}
          isShow={isShow}
          onClose={closePopupFormSales}
          isFullScreen={true}
          isTopBorderTransparent={true}
          isWidthScreen="90vw"
        >
          <HomePopUp />
        </Modal>
        <HomeFloatingButton />
      </main>
    </>
  );
};

export default HomeBusiness;
