import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import { Image } from "components/Image/Image";
import { pushDataLayer } from "lib/gtag";
import { usePopupStoreFormSales } from "modules/Home/popupStore";
import Button from "components/Button";
import { DataInfo } from "./HomeInformation";

export const UserCompanies = [
  /// Row 1
  {
    name: "kemenperin",
    image_url: "https://staging-static.gokampus.com/course-institutions/PgSTbEwHeIaow3LoiMWJL9MCeEowfAqZbRMNFE2X.png",
  },
  {
    name: "pan-rb",
    image_url: "https://www.menpan.go.id/site/images/logo_new.png",
  },
  {
    name: "kapal-api",
    image_url: "https://s.gokampus.com/tenants/h7tBkxynkbFqEEsqmnlBIWLYqs9818UtqOP4TZzz.png",
  },
  {
    name: "bluebird",
    image_url: "https://s.gokampus.com/tenants/7p5nbeVAVOm0qMHWUU5UCafQ6vVp1E7sDS8SqRLG.png",
  },
  {
    name: "darya-varia",
    image_url: "https://s.gokampus.com/business/logo-darya-varia.png",
  },
  {
    name: "aquaproof",
    image_url: "https://s.gokampus.com/business/logo-aquaproof.png",
  },
  /// Row 2
  {
    name: "wonderful-indonesia",
    image_url: "https://s.gokampus.com/business/logo-wonderful-indonesia.png",
  },
  {
    name: "mega-insurance",
    image_url: "https://s.gokampus.com/business/logo-mega-insurance.png",
  },
  {
    name: "boga",
    image_url: "https://s.gokampus.com/business/logo-boga-red.png",
  },
  {
    name: "zap",
    image_url: "https://staging-static.gokampus.com/tenants/zVnouvLjX8dYIo3SeFsveyPrtgKtPjvvJ3bWjIIg.jpeg",
  },
  {
    name: "summarecon-agung",
    image_url: "https://s.gokampus.com/business/logo-summarecon-agung.png",
  },
  {
    name: "indosat-digital-ecosystem",
    // image_url: "https://staging-static.gokampus.com/course-institutions/595gqm2xfr9EZ5CtTYvbcVSXxEONk8ZPLHC7sFLF.png",
    image_url: "https://staging-static.gokampus.com/course-institutions/qxPa26tOotbk4UDG3tiKhDjWrd9wv7XCPPXZ2S0E.png"
  },
  // Row 3
  {
    name: "ppsdm-aparatur-esdm",
    image_url: "https://s.gokampus.com/tenants/WlnVzEy7CcF5VWTrd0IZIi1LDYBY4glIMr9WG71j.jpeg",
  },
  {
    name: "sucor-sekuritas",
    image_url: "https://staging-static.gokampus.com/course-institutions/6FDrcHmhLJzGSi4cqk2YMsDMHhwbFgBF0xSl1lxl.svg",
  },
  {
    name: "arwana-ceramics",
    image_url: "https://s.gokampus.com/business/logo-arwana-ceramics.png",
  },  
  {
    name: "tdr",
    image_url: "https://s.gokampus.com/business/logo-tdr.png",
  },
  {
    name: "blibli",
    image_url: "https://s.gokampus.com/tenants/eNiPV814LTbT43yfGqQrMfYWUBfKUdVRTe2w2ZHx.png",
  },
  {
    name: "emtek",
    image_url: "https://s.gokampus.com/business/logo-emtek.png",
  },
  // {
  //   name: "nobu-bank",
  //   image_url: "https://s.gokampus.com/business/logo-nobu88.png",
  // },
  // {
  //   name: "amartha",
  //   image_url: "https://s.gokampus.com/business/logo-amartha.png",
  // },
  // {
  //   name: "neobank",
  //   image_url: "https://staging-static.gokampus.com/course-institutions/1ueEHBQch2McCkLwxB0ZsUsHFpgnJuN8QQLDM9O1.png",
  // },
  // {
  //   name: "lions",
  //   image_url: "https://staging-static.gokampus.com/course-institutions/HxQdrLTqFUfA8u6qdTWtVXLNpNoAKbzZnUzLuEXM.png",
  // },
  // {
  //   name: "sinarmas-agri",
  //   image_url: "https://s.gokampus.com/business/logo-sinarmas-agri.png",
  // },
  // {
  //   name: "gdsk",
  //   image_url: "https://s.gokampus.com/business/logo-gdsk.png",
  // },
  // {
  //   name: "nmw-clinic",
  //   image_url: "https://s.gokampus.com/business/logo-nmw.png",
  // },
  // {
  //   name: "digitos",
  //   image_url: "https://s.gokampus.com/business/logo-digitos.png",
  // },
  // {
  //   name: "freshminds",
  //   image_url: "https://s.gokampus.com/business/logo-freshminds.png",
  // },
  // {
  //   name: "flextech",
  //   image_url: "https://s.gokampus.com/business/logo-flextech.png",
  // },
  // {
  //   name: "mas",
  //   image_url: "https://s.gokampus.com/business/logo-mas.png",
  // },
];

export const HomeCompanies: React.FC = () => {
  const textCompanies = TranslationID["HomeCompanies"];

  const { showPopupFormSales } = usePopupStoreFormSales((state) => ({
    showPopupFormSales: state.showPopupFormSales,
  }));

  const onClickImages = () => {
    pushDataLayer({
      event: "cta-client",
    });
    showPopupFormSales("");
  };

  const onClickSeeMore = () =>{
    pushDataLayer({
      event: "cta-client-see-more",
    });
    showPopupFormSales("");
  }

  return (
    <section id="home-companies">
      <ContainerDesktop className="py-52 sm-max:py-36" useAuto>
        <div className="grid gap-36 text-center">
          <h3
            className="text-h3 font-black text-neutral-80 lg-max:text-h4"
            dangerouslySetInnerHTML={{
              __html: DataInfo.industry + " " + textCompanies.title,
            }}
          ></h3>
          <div className="cursor-pointer" onClick={() => onClickImages()}>
            <div className="grid gap-30 grid-cols-6 lg-max:grid-cols-3 sm-max:!grid-cols-2">
              {UserCompanies.map((institution) => (
                <div
                  key={Math.random()}
                  className="aspect-[2/1] lg-max:aspect-[2/1] py-8 px-4 bg-white shadow-chip_total rounded-8"
                >
                  <Image
                    src={institution.image_url}
                    alt={institution.name}
                    className={`w-full h-full object-contain`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="relative" onClick={onClickSeeMore}>
            <Button variant="secondary_transparant">Lihat Semua</Button>
          </div>
        </div>
      </ContainerDesktop>
    </section>
  );
};
