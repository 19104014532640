import React, { useState } from "react";
import Button, { Variant } from ".";
import TranslationID from "data/locales/id/business.json";
import FormFieldText from "components/Form/FieldText";
import { IconWarning } from "components/Icon/IconWarning";
import { useToastStore } from "components/Toast/toastStore";
import { usePostContactBusiness } from "modules/Business/businessApi";
import { pushDataLayer } from "lib/gtag";

const ButtonContact: React.FC<{
  className?: string;
  variant?: Variant;
  id?: string;
  inputStep?: number;
}> = ({ className = "", inputStep = 0, variant = "primary", id }) => {
  const headingTranslationButton = TranslationID["Button"];
  const [step, setStep] = useState(inputStep);
  const [contact, setContact] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const showToast = useToastStore((state) => state.showToast);
  const usePostApi = usePostContactBusiness();

  const submit = () => {
    if(!contact){
      showToast({
        type: "danger",
        message: "Pastikan kontak anda sudah diisi",
      });
      return;
    }
    if (inputStep == 1) {
      pushDataLayer({ event: id });
    }

    setIsLoading(true);
    const urlOrigin = window.location.href + "?event=" + id;
    usePostApi.doFetch(
      {
        contact,
        url_origin: urlOrigin,
      },
      {
        onSuccess(res) {
          pushDataLayer({ event: "success-leads" });

          //@ts-ignore
          if (res.message === "success") {
            showToast({
              type: "success",
              message: "Email anda berhasil terkirim ke tim goKampus",
            });
            setIsLoading(false);
            setStep((step) => step + 1);
          }
        },
        onError(err) {
          setIsLoading(false);
          showToast({
            type: "danger",
            message: err.data?.message ?? err.message ?? "Error",
          });
        },
      }
    );
  };

  if (step == 0) {
    return (
      <Button
        className={`flex justify-center items-center gap-8 !rounded-8 !text-b1 ${className}`}
        variant={variant}
        onClick={() => {
          pushDataLayer({ event: id });
          setStep((step) => step + 1);
        }}
      >
        {headingTranslationButton.contactSales}
      </Button>
    );
  } else if (step == 1) {
    return (
      <div className={className}>
        <FormFieldText
          name="promocode"
          islast={true}
          // isFull={window.innerWidth <= 480}
          classBase="h-full sm-max:w-full"
          inputStyle="!rounded-8 h-48 !w-[350px] sm-max:!w-full"
          placeholder="No.handphone/email Anda"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          afterInput={
            <div className="absolute w-fit flex items-center top-0 right-0 h-full px-8">
              <Button
                className="text-14 text-center"
                onClick={submit}
                state={isLoading ? "loading" : "active"}
              >
                Hubungi Sales
              </Button>
            </div>
          }
        />
      </div>
    );
  } else if (step == 2) {
    return (
      <div className="flex gap-4 items-center">
        <Button
          className={`flex justify-center items-center gap-8 !rounded-8 !text-b1 ${className}`}
          variant="secondary_transparant"
          disabled
        >
          {headingTranslationButton.contactSales}
        </Button>
        <div className="group flex relative cursor-pointer">
          <IconWarning color="#19093A" />

          <div className="group-hover:opacity-100 w-400 border border-neutral-40 z-50 p-12 transition-opacity bg-white font-light rounded-12 absolute left-24 top-0 opacity-0">
            Anda sudah mengirim email ke tim goKampus. Kami akan segera
            menghubungi Anda
          </div>
        </div>
      </div>
    );
  }
};

export default ButtonContact;
