import { ContainerDesktop } from "components/Container";
import { Logo } from "components/Logo";
import React from "react";

const FooterBusiness: React.FC = ({}) => {

  return (
    <footer>
      <ContainerDesktop
        classBackgroundFull="bg-purple-05 !h-[200px] h-full flex items-center justify-center"
        className="flex"
        useAuto
      >
        <div className="flex flex-col justify-center items-center w-full gap-12 text-purple-50">
          <Logo />
          <div className="font-light">#LearnBetterGoFaster with goKampus!</div>
          <div className="font-light text-b1">
            © 2024 PT. Sistem Sarjana Sejahtera.
          </div>
        </div>
      </ContainerDesktop>
    </footer>
  );
};

export default FooterBusiness;
