import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Autoplay } from "swiper";
import { Image } from "components/Image/Image";

SwiperCore.use([Autoplay, EffectFade]);

const ImageAutoplay: React.FC<{
  dataImages?: string[];
  className?: string;
}> = ({ dataImages, className = "" }) => {
  const settings = {
    className: "swiper-home",
    slidesPerView: 1,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    freeMode: true,
    effect: "fade",
    loop: true,
    speed: 2500,
  };

  return (
    <>
      {dataImages && dataImages.length > 0 ? (
        <Swiper {...settings}>
          {dataImages.map((image, i) => (
            <SwiperSlide key={"SwiperSlide" + i}>
              <Image
                src={image}
                className={`${className}`}
                alt="Image"
                width={500}
                height={300}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}
    </>
  );
};

export default ImageAutoplay;
