import TranslationID from "data/locales/id/business.json";
import { useState } from "react";
import { usePacketStore } from "./packetStore";
import { formatCurrency } from "lib/currency";
import { Divider } from "components/Divider/Divider";
import { IconCheckCircle } from "components/Icon/IconCheckCircle";
import Button from "components/Button";
import { pushDataLayer } from "lib/gtag";
import { PopupModal } from "react-calendly";
import { isBrowser } from "lib/ssr";
import { usePopupStoreFormSales } from "modules/Home/popupStore";
import Image from "next/image";

export const HomePacketCards: React.FC = () => {
  const headingSectionPackets = TranslationID["HomePackets"];
  const packets = headingSectionPackets.packets.filter(
    (packet) => packet?.benefits?.length > 0
  );
  const [isOpen, setIsOpen] = useState(false);

  const { showPopupFormSales } = usePopupStoreFormSales((state) => ({
    showPopupFormSales: state.showPopupFormSales,
  }));

  const { selectedType, isIncludeCertificate } = usePacketStore((state) => ({
    selectedType: state.selectedType,
    isIncludeCertificate: state.isIncludeCertificate,
  }));

  const onClickItem = (itemId, index) => {
    pushDataLayer({ event: itemId });
    /// Enterprise Index = Jadwalkan Sekarang
    if (index == 3) {
      setIsOpen(!isOpen);
    } else {
      /// Others Packets
      showPopupFormSales(itemId);
    }
  };

  return (
    <div className="flex gap-20 h-full lg-max:flex-col">
      {packets.map((packet, i) => (
        <div
          key={packet.label + i}
          className="w-1/3 lg-max:w-full flex flex-col"
          onClick={() => onClickItem(packet.id, i)}
        >
          <div
            className={`rounded-t-16 py-8 px-10 text-transparent justify-center ${
              packet.tagline
                ? `flex ${
                    packet.selected
                      ? "bg-purple-50 !text-white"
                      : "bg-transparent sm-max:hidden"
                  }`
                : "bg-transparent sm-max:hidden"
            }`}
          >
            {packet.tagline ?? "tagline"}
          </div>

          <div
            className={`bg-white p-20 shadow-packet h-full flex flex-col ${
              packet.selected
                ? packet.tagline
                  ? "rounded-b-16 border-2 border-purple-50"
                  : "rounded-16 border-2 border-purple-50"
                : "rounded-16 border border-neutral20_to_dark30"
            }`}
          >
            <div
              className="font-bold text-h4 text-neutral-80 mb-8"
              dangerouslySetInnerHTML={{
                __html: packet.label,
              }}
            ></div>

            <div className="h-full items-center">
              {selectedType == "Tahun"
                ? packet.price && (
                    <div
                      className={`font-light text-b2 line-through ${"text-neutral-60"}`}
                    >
                      Rp {formatCurrency(isIncludeCertificate? packet.price_certificate : packet.price)}
                    </div>
                  )
                : null}

              {packet.price ? (
                <div className="text-orange-50 flex items-center justify-center">
                  <div className="text-b1 font-light">Rp&nbsp;</div>
                  <div className="font-bold text-h3">
                    {selectedType == "Tahun"
                      ? formatCurrency(isIncludeCertificate? packet.price_certificate_discount : packet.price_after_discount)
                      : formatCurrency(isIncludeCertificate? packet.price_certificate : packet.price)}
                  </div>
                  <div className="text-b1 font-light">/Bulan</div>
                </div>
              ) : (
                // Jadwalkan Demo
                <div className="font-medium text-neutral-80">
                  {packet.description}
                </div>
              )}

              <div className="text-neutral-60 font-light">
                Pembayaran per {selectedType ? selectedType.toLowerCase() : ""}
              </div>
            </div>

            {packet.button !== "Jadwalkan Demo" ? (
              <div className="relative" id={packet.button}>
                <Button
                  isFull
                  className="mt-16"
                  variant={packet.selected ? "primary" : "secondary"}
                  onClick={() => onClickItem(packet.id, i)}
                  id={packet.id}
                >
                  {packet.button}
                </Button>
              </div>
            ) : (
              <div className="relative" id={packet.button}>
                <Button
                  className={`mt-16`}
                  onClick={() => {
                    pushDataLayer({ event: packet.id });
                    setIsOpen(!isOpen);
                  }}
                  variant={packet.selected ? "primary" : "secondary"}
                  isFull
                  id={packet.id}
                >
                  {packet.button}
                </Button>

                <PopupModal
                  url="https://calendly.com/gokampus/b2b-demo"
                  onModalClose={() => setIsOpen(false)}
                  open={isOpen}
                  rootElement={
                    isBrowser() ? document.getElementById("__next") : null
                  }
                  pageSettings={{
                    backgroundColor: "ffffff",
                    hideEventTypeDetails: false,
                    hideLandingPageDetails: false,
                    primaryColor: "00a2ff",
                    textColor: "4d5055",
                  }}
                />
              </div>
            )}

            <Divider className="my-24 border-neutral20_to_dark30" />

            {packet.benefits?.length > 0 ? (
              <div className="grid gap-8 mb-8">
                {packet.benefits.map((elm) => (
                  <div className="flex justify-start gap-8" key={elm}>
                    <div className="w-24">
                      <IconCheckCircle
                        color="#46CA6B"
                        size={24}
                        isSameHeight={true}
                      />
                    </div>
                    <div
                      className="text-left text-neutral-80 font-light"
                      dangerouslySetInnerHTML={{
                        __html: elm,
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};
