import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import { IconBooks } from "components/Icon/IconBooks";
import { IconElectric } from "components/Icon/IconElectric";
import { IconCurrency } from "components/Icon/IconCurrency";
import Button from "components/Button";
import { useRouter } from "next/router";
import { pushDataLayer } from "lib/gtag";
import { usePopupStoreFormSales } from "modules/Home/popupStore";
import { Image } from "components/Image/Image";

export const HomeBenefit: React.FC = () => {
  const headingSectionBenefit = TranslationID["HomeBenefit"];
  const router = useRouter();

  const { showPopupFormSales } = usePopupStoreFormSales((state) => ({
    showPopupFormSales: state.showPopupFormSales,
  }));

  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // push to gtm event
    pushDataLayer({ event: "benefit-price" });
    // first prevent the default behavior
    e.preventDefault();
    // get the href and remove everything before the hash (#)
    const href = e.currentTarget.href;
    const targetId = href.replace(/.*\#/, "");
    // get the element by id and use scrollIntoView
    const elem = document.getElementById(targetId);
    elem?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  const renderDescription = (contents: any, isImageLeft: boolean) => {
    let buttonItem = <div></div>;
    if (contents.icon == "book") {
      buttonItem = (
        <Button
          // className={`flex w-full !text-b1 !h-48`}
          className={`!text-b1 hg-max:!text-b2 !h-40`}
          variant="secondary_transparant"
          onClick={() => {
            pushDataLayer({ event: "benefit-install" });
            showPopupFormSales("");
          }}
        >
          {contents.buttonDesc}
        </Button>
      );
    } else if (contents.icon == "electric") {
      buttonItem = (
        <Button
          // className={`flex w-full !text-b1 !h-48`}
          className={`!text-b1 hg-max:!text-b2 !h-40`}
          variant="secondary_transparant"
          onClick={() => {
            pushDataLayer({ event: "benefit-report" });
            showPopupFormSales("");
          }}
        >
          {contents.buttonDesc}
        </Button>
      );
    } else if (contents.icon == "currency") {
      buttonItem = (
        <a
          //@ts-ignore
          href={"#home-packets"}
          onClick={handleScroll}
          // className={`flex w-full !h-48`}
          className={`!h-40`}
        >
          <Button
            variant="secondary_transparant"
            className="!h-full !text-b1 hg-max:!text-b2"
            isFull
          >
            {contents.buttonDesc}
          </Button>
        </a>
      );
    }

    return isImageLeft ? (
      <div className="grid grid-cols-2 gap-16 p-24 bg-total-pattern rounded-12 shadow-chip_total lg-max:grid-cols-1">
        <div className="relative">
          <Image
            className={`w-full h-full object-contain rounded-8`}
            src={contents.imageUrl}
            alt={contents.imageAlt}
          />
        </div>
        <div className="relative">
          <div
            key={contents.title + Math.random()}
            className="h-full flex flex-col gap-16 justify-center items-start rounded-16 bg-chip-pattern p-24"
          >
            {/* <div className="bg-purple-60 w-48 h-48 mb-20 flex justify-center rounded-full items-center">
              {
                {
                  book: <IconBooks />,
                  electric: <IconElectric />,
                  currency: <IconCurrency />,
                }[contents.icon]
              }
            </div> */}
            <h3 className="text-start text-purple-50 text-h2 font-bold sm-max:text-b1 py-4">
              {contents.title}
            </h3>

            <div className="text-start text-neutral-80 text-b1 font-light sm-max:text-b2">
              {contents.description}
            </div>

            {/* {contents.icon == "book" && (
              <div className="grid grid-cols-2 hg-max:grid-cols-1 gap-8 w-full py-8">
                <div className="rounded-8 border border-neutral-10 grid">
                  <div className="rounded-t-8 bg-green-10 text-green-70 font-medium text-left py-4 px-8 flex items-center">
                    LIVE
                  </div>

                  <div className="rounded-b-8 flex font-light justify-start items-center gap-4 p-8">
                    <img src={"/images/icon-moodle.png"} />
                    Moodle
                  </div>
                </div>

                <div className="rounded-8 border border-neutral-10 grid">
                  <div className="rounded-t-8 bg-neutral-10 text-neutral-50 font-medium text-left py-4 px-8 flex items-center">
                    Coming Soon
                  </div>

                  <div className="rounded-b-8 flex font-light text-b2 justify-start items-center gap-4 p-8">
                    <div className="flex">
                      <img src={"/images/icon-moodle-1.png"} />
                      <img
                        src={"/images/icon-moodle-2.png"}
                        className="-ml-14"
                      />
                      <img
                        src={"/images/icon-moodle-3.png"}
                        className="-ml-14"
                      />
                    </div>
                    dan lainnya...
                  </div>
                </div>
              </div>
            )} */}

            {buttonItem}
          </div>
        </div>
      </div>
    ) : (
      <div className="grid grid-cols-2 gap-16 p-24 bg-total-pattern rounded-12 shadow-chip_total lg-max:grid-cols-1">
        <div
          key={contents.title + Math.random()}
          className="h-full flex flex-col gap-16 justify-center items-start rounded-16 bg-chip-pattern p-24"
        >
          {/* <div className="bg-purple-60 w-48 h-48 mb-20 flex justify-center rounded-full items-center">
            {
              {
                book: <IconBooks />,
                electric: <IconElectric />,
                currency: <IconCurrency />,
              }[contents.icon]
            }
          </div> */}
          <h3 className="text-start text-purple-50 text-h2 font-bold sm-max:text-b1 py-4">
            {contents.title}
          </h3>

          <div className="text-start text-neutral-80 text-b1 font-light sm-max:text-b2">
            {contents.description}
          </div>

          {contents.icon == "book" && (
            <div className="grid grid-cols-2 hg-max:grid-cols-1 gap-8 w-full py-8">
              <div className="rounded-8 border border-neutral-10 grid">
                <div className="rounded-t-8 bg-green-10 text-green-70 font-medium text-left py-4 px-8 flex items-center">
                  LIVE
                </div>

                <div className="rounded-b-8 flex font-light justify-start items-center gap-4 p-8">
                  <img src={"/images/icon-moodle.png"} />
                  Moodle
                </div>
              </div>

              <div className="rounded-8 border border-neutral-10 grid">
                <div className="rounded-t-8 bg-neutral-10 text-neutral-50 font-medium text-left py-4 px-8 flex items-center">
                  Coming Soon
                </div>

                <div className="rounded-b-8 flex font-light text-b2 justify-start items-center gap-4 p-8">
                  <div className="flex">
                    <img src={"/images/icon-moodle-1.png"} />
                    <img src={"/images/icon-moodle-2.png"} className="-ml-14" />
                    <img src={"/images/icon-moodle-3.png"} className="-ml-14" />
                  </div>
                  dan lainnya...
                </div>
              </div>
            </div>
          )}

          {buttonItem}
        </div>
        <div className="relative lg-max:order-first">
          <Image
            className={`w-full h-full object-contain rounded-8`}
            src={contents.imageUrl}
            alt={contents.imageAlt}
          />
        </div>
      </div>
    );
  };

  return (
    <section id="home-benefit">
      <ContainerDesktop className="py-52 sm-max:py-36" useAuto>
        <div className="grid gap-24 justify-center text-center">
          <div className="grid gap-8">
            <h2 className="text-h3 font-black text-neutral-80 sm-max:text-h4">
              {headingSectionBenefit.title}
            </h2>

            <div className="text-h4 font-light text-neutral-70 sm-max:text-b1">
              {headingSectionBenefit.description}
            </div>
          </div>

          {/* First Benefit */}
          {renderDescription(headingSectionBenefit.content[0], true)}

          {/* Second Benefit */}
          {renderDescription(headingSectionBenefit.content[1], false)}

          {/* Third Benefit */}
          {renderDescription(headingSectionBenefit.content[2], true)}
        </div>
      </ContainerDesktop>
    </section>
  );
};
